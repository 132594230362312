import React from 'react'
import PropTypes from 'prop-types'

import { Flex } from 'Components/UI'
import { Pills } from 'Components/Blocks'

const CategoryPills = ({ categories, ...rest }) => {
  if (!categories) {
    return null
  }

  const pills = categories.map(category => ({
    label: category.name,
  }))

  return (
    <Flex {...rest}>
      <Pills big collapse pills={pills} />
    </Flex>
  )
}

CategoryPills.defaultProps = {}

CategoryPills.propTypes = {
  categories: PropTypes.array.isRequired,
}

export default CategoryPills
