import React from 'react'
import PropTypes from 'prop-types'

import { useBaseTheme } from 'Hooks'

import { Box } from 'Components/UI'
import { Container, GroupedServicesTitleText, SocialContainer } from './styles'
import PricePanel from './PricePanel'
import Social from '../Content/Social'

function StickyPanel({ service, onShowWidget, ...rest }) {
  const { bookingPanelCopy } = useBaseTheme()

  return (
    <Container {...rest}>
      <Box>
        {bookingPanelCopy && (
          <GroupedServicesTitleText>
            {bookingPanelCopy}
          </GroupedServicesTitleText>
        )}
      </Box>

      <PricePanel service={service} onShowWidget={onShowWidget} />

      <SocialContainer>
        <Social service={service} />
      </SocialContainer>
    </Container>
  )
}

StickyPanel.defaultProps = {
  service: null,
}

StickyPanel.propTypes = {
  service: PropTypes.object,
  onShowWidget: PropTypes.func.isRequired,
}

export default StickyPanel
