import React, { useState, useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import momentPropTypes from 'react-moment-proptypes'
import moment from 'moment'
import { get, map } from 'lodash'

import { _ } from 'Services/I18n'
import Labels from 'Constants/labels'
import { Box, Flex } from 'Components/UI'
import { useBaseTheme } from 'Hooks'
import Timeslots from './Timeslots'

import {
  ArrowIcon,
  DatePickerIcon,
  Day,
  DayDash,
  DayLabel,
  Location,
  Locations,
  MonthLabel,
  MoreOptions,
  NextArrow,
  TimeslotLabel,
  PrevArrow,
  DaysSlider,
  LocationSelect,
} from './styles'

import { ContentTitle, Dash } from '../../styles'

const AvailableDates = ({
  availabilityDate,
  availableDates,
  service,
  onShowWidget,
  ...rest
}) => {
  const getDefaultSelectedDateIndex = () => {
    // No available dates at all
    if (availableDates.length === 0) {
      return 0
    }

    // No date selected in the date picker
    if (!availabilityDate) {
      return 0
    }

    const dates = Object.keys(availableDates[0].dates)
    const index = dates.findIndex(
      date => date === availabilityDate.format('YYYY-MM-DD'),
    )

    return index === -1 ? 0 : index
  }

  const { secondaryColor, labels, primaryColor, primaryTextColor } =
    useBaseTheme()
  const [activeLocation, setActiveLocation] = useState(0)
  const [activeDay, setActiveDay] = useState(getDefaultSelectedDateIndex())
  const daySliderRef = useRef()

  const handleLocationClick = useCallback(index => {
    setActiveLocation(index)

    daySliderRef.current?.slickGoTo(0)
    setActiveDay(0)
  }, [])

  const handleDayClick = useCallback(index => setActiveDay(index), [])

  const getActiveLocation = () => availableDates[activeLocation]

  const getActiveDate = () => {
    const activeLocationDates = getActiveLocation().dates

    return Object.keys(activeLocationDates)[activeDay]
  }

  const renderMobileLocationsDropdown = () => {
    const options = map(availableDates, (location, index) => ({
      label: location.location_name,
      value: index,
    }))

    return (
      <LocationSelect
        color={primaryColor}
        isMulti={false}
        isSearchable={false}
        options={options}
        placeholder={_('common.locations')}
        value={options[activeLocation]}
        onChange={option => handleLocationClick(option.value)}
      />
    )
  }

  const renderDateRange = () => {
    const startDate = moment(service.start_date).format('ddd, D MMM YYYY')
    const endDate = moment(service.end_date).format('ddd, D MMM YYYY')

    return (
      <TimeslotLabel>
        <DatePickerIcon color={secondaryColor} size={18} />
        {startDate} - {endDate}
      </TimeslotLabel>
    )
  }

  const daysSliderSettings = {
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    speed: 250,
    dots: false,
    arrows: true,
    swipeToSlide: true,
    nextArrow: (
      <NextArrow fill={primaryColor}>
        <ArrowIcon />
      </NextArrow>
    ),
    prevArrow: (
      <PrevArrow fill={primaryColor}>
        <ArrowIcon />
      </PrevArrow>
    ),
    responsive: [
      {
        breakpoint: 1130,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1030,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 648,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 548,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 448,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 348,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }

  const renderDays = useMemo(() => {
    const activeLocationDates = getActiveLocation()?.dates || {}
    const days = Object.keys(activeLocationDates)

    // Hide the section if there's only one date
    if (days.length === 1) {
      return null
    }

    const moreOptions =
      days.length > 5 ? (
        <Box key="more_options">
          <MoreOptions onClick={() => onShowWidget(false)}>
            <DatePickerIcon color="#52525B" size={22} />
            More Options
          </MoreOptions>
        </Box>
      ) : null

    const dayTiles = days.slice(0, 5).map((day, index) => {
      const dayMoment = moment(day)

      return (
        <Box key={day} mb={3}>
          <Day
            active={activeDay === index}
            bgcolor={primaryColor}
            color={primaryTextColor}
            onClick={() => handleDayClick(index)}
          >
            <Flex>{dayMoment.format('dddd')}</Flex>
            <DayDash />
            <MonthLabel>{dayMoment.format('MMMM')}</MonthLabel>
            <DayLabel className="day-label">{dayMoment.format('D')}</DayLabel>
          </Day>
        </Box>
      )
    })

    return [...dayTiles, moreOptions]
  }, [availableDates, activeLocation, activeDay])

  const renderTimesForDate = useMemo(() => {
    const activeLocationDates = getActiveLocation()?.dates

    if (!activeLocationDates) {
      return null
    }

    const activeDate = getActiveDate()

    const times = activeLocationDates[activeDate]

    const date = moment(activeDate).format('ddd, D MMM YYYY')

    return <Timeslots date={date} times={times} />
  }, [availableDates, activeLocation, activeDay])

  const renderScheduledDates = () => {
    // Show date range if no available dates
    if (availableDates.length === 0) {
      return renderDateRange()
    }

    return (
      <>
        {availableDates.length > 1 && (
          <>
            <Locations display={['none', 'none', 'flex']} mb={3}>
              {availableDates.map((location, index) => (
                <Location
                  active={activeLocation === index}
                  bgcolor={primaryColor}
                  color={primaryTextColor}
                  key={location.location_id}
                  onClick={() => handleLocationClick(index)}
                >
                  {location.location_name}
                </Location>
              ))}
            </Locations>
            <Box display={['block', 'block', 'none']} mb={3}>
              {renderMobileLocationsDropdown()}
            </Box>
          </>
        )}

        <DaysSlider ref={daySliderRef} {...daysSliderSettings}>
          {renderDays}
        </DaysSlider>

        {renderTimesForDate}
      </>
    )
  }

  // Hide section if non-scheduled service and no end date
  if (!service.is_scheduled && !service.end_date) {
    return null
  }

  // Hide section if scheduled service and no available dates and no end date
  if (
    service.is_scheduled &&
    availableDates.length === 0 &&
    !service.end_date
  ) {
    return null
  }

  return (
    <Box {...rest}>
      <ContentTitle>
        {get(labels, Labels.TITLE_SERVICE_DATE, 'Date')}
      </ContentTitle>
      <Dash />
      <Box mt="16px">
        {service.is_scheduled ? renderScheduledDates() : renderDateRange()}
      </Box>
    </Box>
  )
}

AvailableDates.defaultProps = {
  availabilityDate: null,
  availableDates: [],
}

AvailableDates.propTypes = {
  availabilityDate: momentPropTypes.momentObj,
  availableDates: PropTypes.array,
  service: PropTypes.object.isRequired,
  onShowWidget: PropTypes.func.isRequired,
}

export default AvailableDates
