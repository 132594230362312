import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
  loadService,
  loadServiceSurroundingDates,
} from 'Store/Actions/marketplace'

import {
  getCategoryValue,
  getIsLoadingService,
  getIsLoadingServiceSurroundingDates,
  getSearchDate,
  getService,
  getServiceError,
} from 'Store/Selectors/marketplace'

import Service from './Service'

const selector = createStructuredSelector({
  availabilityDate: getSearchDate,
  categoryValue: getCategoryValue,
  isLoadingService: getIsLoadingService,
  isLoadingServiceSurroundingDates: getIsLoadingServiceSurroundingDates,
  service: getService,
  serviceError: getServiceError,
})

const actions = {
  onLoadService: loadService,
  onLoadServiceSurroundingDates: loadServiceSurroundingDates,
}

export default connect(selector, actions)(Service)
