import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

import { Flex, Icon } from 'Components/UI'
import { zoomInGlyph } from 'Assets/Svg'

export const Container = styled(Flex).attrs({
  flexDirection: ['column', 'column', 'row'],
})`
  flex: 1;
  gap: 18px;
  margin-top: 24px;
`

export const BusinessName = styled.h2`
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  color: ${({ color }) => color};
  cursor: pointer;

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    text-align: center;
  }

  &:hover {
    opacity: 0.7;
  }
`

export const LocationRatingWrapper = styled(Flex).attrs({
  flexWrap: 'wrap',
})`
  row-gap: 12px;
  justify-content: flex-start;

  @media screen and (max-width: ${themeGet('breakpoints.0')}) {
    justify-content: center;
  }
`

export const MerchantLogo = styled.img`
  max-height: 60px;
  max-width: 180px;
  align-self: center;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`

export const Well = styled(Flex).attrs({
  justifyContent: 'center',
  p: 3,
})`
  background-color: ${themeGet('colors.pearl')};
  box-shadow: inset 0 0 10px #f2f2f2;
  border-radius: ${themeGet('borderRadius.1')};
  gap: 18px;
`

export const Button = styled(Flex).attrs({
  py: 2,
  px: [3, 4],
})`
  border-radius: ${themeGet('borderRadius.2')};
  border: 1px solid ${themeGet('colors.lightGrey')};
  background-color: white;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.7;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 9px 2px;
    -webkit-transition:
      all 0.2s ease 0s,
      -webkit-transform 0.2s ease 0s;
    -webkit-transition:
      all 0.2s ease 0s,
      -webkit-transform 0.2s ease 0s;
    transition:
      all 0.2s ease 0s,
      -webkit-transform 0.2s ease 0s;
  }
`

export const FindOutMoreIcon = styled(Icon).attrs({
  glyph: zoomInGlyph,
  size: 16,
})`
  fill: #52525b;
`
