import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'
import { Flex, Icon } from 'Components/UI'

import { tagGlyph } from 'Assets/Svg'

export const Wrapper = styled(Flex).attrs({})`
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
`

export const Container = styled(Flex).attrs({
  flexDirection: 'column',
})``

export const Header = styled(Flex).attrs({})`
  gap: 12px;
`

export const Content = styled(Flex).attrs({})`
  font-size: 14px;

  ul {
    margin-block-start: 10px;
    margin-block-end: 10px;
  }

  li {
    color: ${themeGet('colors.text')};
    text-transform: none;
    line-height: 1.4;
    font-weight: normal;
    margin-top: 6px;
  }
`

export const FilterLabel = styled(Flex).attrs({})`
  flex: 1;
  font-weight: 700;
  font-size: 16px;
`

export const TagIcon = styled(Icon).attrs({
  glyph: tagGlyph,
  size: 16,
})`
  color: #000;
`
