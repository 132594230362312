import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import map from 'lodash/map'

import { _ } from 'Services/I18n'

import { Box, Flex } from 'Components/UI'

import {
  Container,
  Content,
  FilterLabel,
  Header,
  TagIcon,
  Wrapper,
} from './styles'
import { ContentTitle, Dash } from '../../styles'

const ClientCustomFilters = ({ customFilters, ...rest }) => {
  if (customFilters?.length < 1) {
    return null
  }

  return (
    <Box {...rest}>
      <ContentTitle>{_('common.additionalInformation')}</ContentTitle>
      <Dash />
      <Wrapper mt="16px">
        {map(customFilters, customFilter => {
          const options = get(customFilter, 'options', [])

          return (
            <Container
              flex={customFilters.length > 1 ? '0 0 50%' : '1'}
              key={customFilter.id}
            >
              <Header>
                <Flex>
                  <TagIcon />
                </Flex>
                <FilterLabel>{customFilter.label}</FilterLabel>
              </Header>
              <Content>
                <ul>
                  {map(options, option => {
                    return <li key={option.id}>{option.value}</li>
                  })}
                </ul>
              </Content>
            </Container>
          )
        })}
      </Wrapper>
    </Box>
  )
}

ClientCustomFilters.defaultProps = {
  customFilters: [],
}

ClientCustomFilters.propTypes = {
  customFilters: PropTypes.array,
}

export default ClientCustomFilters
