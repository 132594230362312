import React from 'react'

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  XIcon,
} from 'react-share'

import { useHydrated } from 'Hooks'

import { Box } from 'Components/UI'

import IconsContainer from './styles'

const Share = ({ ...rest }) => {
  const hydrated = useHydrated()

  if (!hydrated) {
    return null
  }

  const iconOptions = {
    size: 32,
    // borderRadius: 10,
    bgStyle: { fill: '#52525b' },
    iconFillColor: 'white',
    url: window.location.href,
    round: true,
    className: 'share-icon',
  }

  const shareUrl = window.location.href

  return (
    <Box {...rest}>
      <IconsContainer>
        <FacebookShareButton url={shareUrl}>
          <FacebookIcon {...iconOptions} />
        </FacebookShareButton>
        <TwitterShareButton url={shareUrl}>
          <XIcon {...iconOptions} />
        </TwitterShareButton>
        <LinkedinShareButton url={shareUrl}>
          <LinkedinIcon {...iconOptions} />
        </LinkedinShareButton>
        <EmailShareButton url={shareUrl}>
          <EmailIcon {...iconOptions} />
        </EmailShareButton>
      </IconsContainer>
    </Box>
  )
}

Share.defaultProps = {}

Share.propTypes = {}

export default Share
