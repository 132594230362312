import React from 'react'
import PropTypes from 'prop-types'

import {
  Timeslots as TimeslotsWrapper,
  DatePickerIcon,
  TimeslotLabel,
  TimeslotSeperator,
} from './styles'

const Timeslots = ({ times, date }) => {
  const timeslot = time => {
    if (!time.timeslot_end) {
      return `${time.timeslot_start} ${time.timezone}`
    }

    return `${time.timeslot_start} - ${time.timeslot_end} ${time.timezone}`
  }

  return (
    <TimeslotsWrapper>
      {times.map(time => {
        const labels = [date]

        if (time.is_option) {
          labels.push(time.service_name)
        }

        if (time.schedule_name) {
          labels.push(time.schedule_name)
        }

        if (time.timeslot_start) {
          labels.push(timeslot(time))
        }

        return (
          <TimeslotLabel key={time.schedule_id}>
            <DatePickerIcon size={18} />
            {labels.map((label, index) => (
              <span key={label}>
                {label}
                {index < labels.length - 1 && <TimeslotSeperator />}
              </span>
            ))}
          </TimeslotLabel>
        )
      })}
    </TimeslotsWrapper>
  )
}

Timeslots.defaultProps = {
  times: [],
}

Timeslots.propTypes = {
  date: PropTypes.string.isRequired,
  times: PropTypes.array,
}

export default Timeslots
