import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { Box } from 'Components/UI'
import { useBaseTheme } from 'Hooks'
import Labels from 'Constants/labels'

import { ContentTitle, Dash, DescriptionText } from '../styles'

const Description = ({ description, ...rest }) => {
  if (!description) {
    return null
  }

  const { labels, secondaryColor } = useBaseTheme()

  return (
    <Box {...rest}>
      <ContentTitle>
        {get(labels, Labels.TITLE_SERVICE_ABOUT, 'About')}
      </ContentTitle>
      <Dash />

      <DescriptionText
        dangerouslySetInnerHTML={{ __html: description }}
        linkcolor={secondaryColor}
        mt="16px"
      />
    </Box>
  )
}

Description.defaultProps = {
  description: '',
}

Description.propTypes = {
  description: PropTypes.string,
}

export default Description
