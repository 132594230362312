import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
  getSearchDate,
  getServiceSurroundingDates,
} from 'Store/Selectors/marketplace'

import AvailableDates from './AvailableDates'

const selector = createStructuredSelector({
  availabilityDate: getSearchDate,
  availableDates: getServiceSurroundingDates,
})

const actions = {}

export default connect(selector, actions)(AvailableDates)
