import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import { NavLink, useParams } from 'react-router-dom'
import IframeResizer from 'iframe-resizer-react'
import { Helmet } from 'react-helmet'
import momentPropTypes from 'react-moment-proptypes'

import { get, map } from 'lodash'

import { loadService } from 'Store/Actions/marketplace'
import { _ } from 'Services/I18n'
import * as Utils from 'Services/Utils'
import { processBucketForSearchPathParams } from 'Services/Utils/merchants'
import { PUBLIC_PATHS } from 'Constants/paths'

import {
  SEARCH_TYPE,
  SERVICE_LOCATION_TYPE,
  WISHLIST_TYPE,
} from 'Constants/ids'

import { useAppContext, useBaseTheme } from 'Hooks'

import { Box, Loader, Tooltip } from 'Components/UI'

import {
  BookingWidgetModal,
  Breadcrumbs,
  ErrorContent,
  RatingStars,
  WishlistToggle,
} from 'Components/Blocks'

import StickyPanel from './StickyPanel'

import {
  BackButton,
  BackIcon,
  Container,
  Content,
  ContentTitle,
  Dash,
  MainContent,
  MapIcon,
  MobileStickyPanel,
  Responsive,
  Row,
  ServiceImageBg,
  ServiceImageWrapper,
  StyledTitle,
  SubTitle,
  Top,
  TopSub,
  WrapperContainer,
} from './styles'

import { LocationWellness } from '../Merchant/Content'

import AvailableDates from './Content/AvailableDates'
import ClientCustomFilters from './Content/ClientCustomFilters'
import Merchant from './Content/Merchant'
import Description from './Content/Description'
import Options from './Content/Options'
import PricePanel from './StickyPanel/PricePanel'
import Social from './Content/Social'
import CategoryPills from './Content/CategoryPills'

function Service({
  availabilityDate,
  categoryValue,
  isLoadingService,
  isLoadingServiceSurroundingDates,
  service,
  serviceError,
  onLoadService,
  onLoadServiceSurroundingDates,
}) {
  const history = useHistory()

  const { config } = useAppContext()

  const [bookingWidgetUrl, setBookingWidgetUrl] = useState()

  const { id: token } = useParams()

  const {
    breakpoint,
    isSearchByServiceEnabled,
    isVoucherRequired,
    secondaryColor,
    primaryColor,
    primaryTextColor,
    roundedBorder,
  } = useBaseTheme()

  const locationType = get(
    service,
    'location_type',
    SERVICE_LOCATION_TYPE.PHYSICAL,
  )

  useEffect(() => {
    window.scrollTo({ top: 0 })

    // If in preview mode we don't want to use the API cache
    const useCache = !get(history, 'location.search', '').includes(
      'preview=true',
    )

    onLoadService({ token, cache: useCache })
  }, [])

  useEffect(() => {
    // Service hasn't loaded yet
    if (!service?.token) {
      return
    }

    // Old service, ignore until new service is loaded
    if (token !== service.token) {
      return
    }

    // Only scheduled services support availability
    if (service.is_scheduled === false) {
      return
    }

    const date = availabilityDate
      ? availabilityDate.format('YYYY-MM-DD')
      : undefined

    onLoadServiceSurroundingDates({ date, serviceId: service.id })
  }, [service])

  if (isLoadingService) {
    return (
      <WrapperContainer>
        <Container>
          <Responsive>
            <Loader color={secondaryColor} />
          </Responsive>
        </Container>
      </WrapperContainer>
    )
  }

  const handleGoBack = () => {
    // This would ensure that on goBack(), the previous page from browser history would load
    history.replace(
      get(history, 'location.state.prevPath', '/'),
      get(history, 'location.state', {}),
    )

    history.goBack()
  }

  const showBookingWidget = showAvailabilityWidget => {
    const isCustomBookingUrl = get(service, 'is_custom_booking_url', false)

    const bookingWidgetDateParam =
      availabilityDate && !isCustomBookingUrl
        ? `&date=${availabilityDate.format('YYYY-MM-DD')}`
        : ''

    const availabilityWidgetParam =
      isVoucherRequired && showAvailabilityWidget ? '&book_now=no' : ''

    const url = `${get(
      service,
      'booking_widget_url',
    )}${bookingWidgetDateParam}${availabilityWidgetParam}`

    if (breakpoint === 'mobile' || isCustomBookingUrl) {
      const newWindow = window.open()
      newWindow.opener = null
      newWindow.location = url

      return
    }

    setBookingWidgetUrl(url)
  }

  const getBreadCrumbs = () => {
    const { categoryId, categoryFullName } =
      Utils.getCategoryProps(categoryValue)

    const geoBuckets = get(service, 'geographical_buckets', [])

    return map(geoBuckets, (bucket, bucketType) => {
      const queryParams = {
        ...processBucketForSearchPathParams(
          { ...bucket, bucket_type: bucketType },
          1,
        ),
        categoryId,
        categoryFullName,
      }

      if (isSearchByServiceEnabled) {
        queryParams.searchType = SEARCH_TYPE.SERVICES
      }

      const link = (
        <NavLink
          exact
          key={queryParams.bucketId}
          to={{
            pathname: PUBLIC_PATHS.SEARCH_QUERY(queryParams),
            state: get(history, 'location.state', {}),
          }}
        >
          {queryParams.bucketName}
        </NavLink>
      )

      return {
        id: queryParams.bucketId,
        element: bucketType !== 'state' ? link : queryParams.bucketName,
      }
    })
  }

  const getServiceAddressLocation = () => {
    // Show the `online` label if service location type is online
    if (locationType === SERVICE_LOCATION_TYPE.ONLINE)
      return _('common.onlineService')

    const locationCount = get(service, 'locations.length', 0)

    // Hide completely if no locations or service location type is mobile
    if (!locationCount || locationType === SERVICE_LOCATION_TYPE.MOBILE)
      return null

    if (locationCount > 1) return _('common.multipleLocations')

    return `${get(service, 'locations.0.suburb')}, ${get(
      service,
      'locations.0.state',
    )}`
  }

  const getMerchantAddressLocation = () => {
    const buckets = get(service, 'geographical_buckets', [])
    const state = get(buckets, 'state.bucket_name')
    const suburb = get(buckets, 'suburb.bucket_name')

    return buckets && suburb && state && `${suburb}, ${state}`
  }

  const serviceAddressLocation = getServiceAddressLocation()
  const merchantAddressLocation = getMerchantAddressLocation()

  const reviewWidgetUrl =
    get(service, 'business.reviews.total', 0) > 0
      ? get(service, 'business.reviews.widgetUrl')
      : null

  if (serviceError) {
    return <ErrorContent error={serviceError} onBack={handleGoBack} />
  }

  const showAvailableDates =
    service?.is_scheduled === false ||
    (service?.is_scheduled && !isLoadingServiceSurroundingDates)

  return (
    <WrapperContainer>
      <Container>
        <Responsive>
          <Row>
            <BackButton color={secondaryColor} onClick={handleGoBack}>
              <BackIcon />
              {_('action.back')}
            </BackButton>
            <Breadcrumbs links={getBreadCrumbs()} />
          </Row>
          <Content>
            <MainContent>
              <Top>
                <Tooltip>
                  <StyledTitle>{service?.name}</StyledTitle>
                  <TopSub>
                    {serviceAddressLocation ? (
                      <SubTitle mt={3}>
                        <MapIcon color={secondaryColor} />
                        {serviceAddressLocation}
                      </SubTitle>
                    ) : null}
                  </TopSub>
                </Tooltip>
              </Top>

              <ServiceImageWrapper mb={[3, 3, 0]} mt={[3, 3, 4]}>
                <ServiceImageBg src={service?.image} />
                <WishlistToggle
                  token={token}
                  wishlistType={WISHLIST_TYPE.SERVICE}
                />
              </ServiceImageWrapper>

              {showAvailableDates && (
                <AvailableDates
                  mt="32px"
                  service={service}
                  onShowWidget={showBookingWidget}
                />
              )}

              <Description description={service?.description} mt="32px" />

              <CategoryPills categories={service?.categories} mt="24px" />

              <Options mb="16px" mt="32px" service={service} />

              <ClientCustomFilters
                customFilters={service?.client_custom_filters}
                mt="32px"
              />

              {service?.locations.length > 0 &&
                locationType === SERVICE_LOCATION_TYPE.PHYSICAL && (
                  <LocationWellness
                    color={secondaryColor}
                    hidePhone
                    merchant={{
                      locations: service?.locations,
                      multiple_locations: service?.locations.length > 1,
                    }}
                    mt="32px"
                    primaryBackground={primaryColor}
                    primaryColor={primaryTextColor}
                    roundedButton={roundedBorder === 1}
                  />
                )}

              <Merchant business={service?.business} mt="32px">
                {merchantAddressLocation ? (
                  <>
                    <MapIcon color={secondaryColor} />
                    <SubTitle mr={16} mt="0">
                      {merchantAddressLocation}
                    </SubTitle>
                  </>
                ) : null}

                <RatingStars
                  enableOnClick={false}
                  mt="-2px"
                  reviews={service?.business?.reviews}
                  size={14}
                />
              </Merchant>

              <Social
                display={['block', 'block', 'block', 'none']}
                mt="32px"
                service={service}
              />

              {reviewWidgetUrl ? (
                <>
                  <ContentTitle mt="32px">{_('common.reviews')}</ContentTitle>
                  <Dash />
                  <Box mt="16px" mx={['-0.5rem', '-0.5rem', '-1rem']}>
                    <IframeResizer
                      className="reviews-widget"
                      frameBorder="0"
                      minHeight={500}
                      src={reviewWidgetUrl}
                      title="Reviews Widget"
                      width="100%"
                    />
                  </Box>
                </>
              ) : null}
            </MainContent>
          </Content>
        </Responsive>
      </Container>

      <StickyPanel
        display={[
          'none !important',
          'none !important',
          'none !important',
          'block !important',
        ]}
        mt="62px"
        service={service}
        onShowWidget={showBookingWidget}
      />

      <MobileStickyPanel>
        <PricePanel
          border="none !important"
          service={service}
          onShowWidget={showBookingWidget}
        />
      </MobileStickyPanel>

      <Helmet>
        <meta content={service?.name} property="og:title" />
        <meta content={service?.summary} property="og:description" />
        <meta content={service?.image} property="og:image" />
        <meta
          content={`https://${get(config, 'host')}${get(
            history,
            'location.pathname',
          )}`}
          property="og:url"
        />
        <meta content="website" property="og:type" />
      </Helmet>

      {bookingWidgetUrl && (
        <BookingWidgetModal
          url={bookingWidgetUrl}
          onClose={() => setBookingWidgetUrl(false)}
        />
      )}
    </WrapperContainer>
  )
}

Service.fetchData = (store, match, query) => {
  const { dispatch } = store
  const token = get(match, 'params.id')

  // If in preview mode we don't want to use the API cache
  const useCache = !query.preview

  return dispatch(loadService({ token, cache: useCache, ssr: true }))
}

Service.defaultProps = {
  availabilityDate: null,
  categoryValue: null,
  service: null,
  serviceError: null,
}

Service.propTypes = {
  availabilityDate: momentPropTypes.momentObj,
  categoryValue: PropTypes.object,
  isLoadingService: PropTypes.bool.isRequired,
  isLoadingServiceSurroundingDates: PropTypes.bool.isRequired,
  service: PropTypes.object,
  serviceError: PropTypes.object,
  onLoadService: PropTypes.func.isRequired,
  onLoadServiceSurroundingDates: PropTypes.func.isRequired,
}

export default Service
