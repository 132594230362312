import styled from 'styled-components'

import { Flex } from 'Components/UI'

export default styled(Flex).attrs({})`
  flex: 1;
  gap: 18px;

  .share-icon:hover {
    opacity: 0.7;
  }
`
