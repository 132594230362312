import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { Box, Dash, Dot, Flex, Icon, Select } from 'Components/UI'
import { calendarGlyph, chevronGlyph } from 'Assets/Svg'
import Slider from 'react-slick'

export const DatePickerIcon = styled(Icon).attrs({
  glyph: calendarGlyph,
})``

export const TimeslotLabel = styled(Flex).attrs({})`
  line-height: normal;
  gap: 8px;
  color: ${themeGet('colors.text')};
  font-size: 14px;
  flex-wrap: wrap;
`

export const Locations = styled(Flex).attrs({})`
  gap: 12px;
  flex-wrap: wrap;
`

export const Location = styled(Box).attrs({})`
  text-align: center;
  border-radius: ${themeGet('borderRadius.1')};
  font-weight: 600;
  padding: 6px 16px;
  font-size: 12px;
  cursor: pointer;

  border: 1px solid
    ${({ active, bgcolor }) => (active ? bgcolor : themeGet('colors.alto'))};

  background: ${({ active, bgcolor }) =>
    active ? bgcolor : themeGet('colors.seashell')};

  color: ${({ active, color }) => (active ? color : themeGet('colors.text'))};

  &:hover {
    border-color: ${({ bgcolor }) => bgcolor};
    background: ${({ bgcolor }) => bgcolor};
    color: ${({ color }) => color};
  }
`

export const DayTile = styled(Flex).attrs({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})`
  height: 120px;
  width: 90%;

  font-size: 12px;
  font-weight: 700;
  color: ${themeGet('colors.text')};

  cursor: pointer;
  border-radius: ${themeGet('borderRadius.1')};
  border: 2px solid ${themeGet('colors.alto')};
`

export const MoreOptions = styled(DayTile).attrs({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})`
  gap: 12px;

  &:hover {
    background-color: ${themeGet('colors.seashell')};
  }
`

export const Day = styled(DayTile).attrs({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})`
  gap: 7px;

  border-color: ${({ active, bgcolor }) =>
    active ? bgcolor : themeGet('colors.alto')};

  .day-label {
    background: ${({ active, bgcolor }) =>
      active ? bgcolor : themeGet('colors.seashell')};

    color: ${({ active, color }) => (active ? color : themeGet('colors.text'))};
  }

  @media (hover: hover) {
    &:hover {
      border-color: ${({ bgcolor }) => bgcolor};

      .day-label {
        background: ${({ bgcolor }) => bgcolor};
        color: ${({ color }) => color};
      }
    }
  }
`

export const DayDash = styled(Dash).attrs({})`
  background-color: ${themeGet('colors.mercury')};
  width: 80%;
`

export const MonthLabel = styled(Flex).attrs({})`
  font-size: 12px;
  font-weight: 500;
  color: ${themeGet('colors.text')};
`

export const DayLabel = styled(Flex).attrs({
  justifyContent: 'center',
  alignItems: 'center',
})`
  width: 38px;
  height: 38px;

  font-size: 14px;
  font-weight: 700;

  border-radius: 100%;
`

export const NextArrow = styled(Box).attrs({
  blacklist: ['slideCount', 'currentSlide'],
})`
  width: 20px !important;
  height: 20px !important;
  cursor: pointer !important;
  position: absolute !important;

  background-color: transparent;

  align-items: center;
  display: flex !important;
  justify-content: center;

  ::before {
    display: none !important;
    content: '' !important;
  }

  transform: translateY(-50%) rotate(90deg) !important;

  fill: ${({ fill }) => fill || themeGet('colors.alto')};

  &.slick-disabled {
    display: none !important;
    fill: ${themeGet('colors.alto')} !important;
  }

  &.slick-next {
    right: -15px;
  }

  &.slick-prev {
    left: -18px;
  }
`

export const PrevArrow = styled(NextArrow)`
  transform: translateY(-50%) rotate(270deg) !important;
`

export const ArrowIcon = styled(Icon).attrs({
  glyph: chevronGlyph,
  size: 18,
})``

export const DaysSlider = styled(Slider).attrs({})`
  .slick-track {
    margin: 0 !important;
  }
`

export const Timeslots = styled(Flex).attrs({
  flexDirection: 'column',
})`
  gap: 8px;
  font-weight: 600;
`

export const TimeslotSeperator = styled(Dot).attrs({
  size: 4,
})`
  margin-bottom: 3px;
  margin-left: 10px;
  background-color: ${({ color }) => color || themeGet('colors.text')};
`

export const LocationSelect = styled(Select)`
  border: 1px solid ${themeGet('colors.concrete')};

  &:active,
  &:focus-within {
    border-color: ${({ color }) =>
      color || themeGet('colors.tealBlue')} !important;
  }

  .react-select {
    &__single-value {
      color: ${({ color }) => color || themeGet('colors.tealBlue')} !important;
      font-weight: 300;
      margin-left: 32px;
    }
    &__value-container {
      padding-left: 16px;
    }
    &__option {
      color: #555;
    }
    &__option--is-selected {
      color: white;
      background-color: ${({ color }) =>
        color || themeGet('colors.tealBlue')} !important;
    }

    &__menu {
      background: white;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      position: absolute;
    }
  }

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    .react-select {
      &__single-value {
        margin-left: 0;
      }
    }
  }
`
