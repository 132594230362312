import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'

import { useBaseTheme } from 'Hooks'
import { _ } from 'Services/I18n'

import { DescriptionText } from '../../styles'

const Description = ({ description, maxLine, moreLinkClass, ...rest }) => {
  if (!description) {
    return null
  }

  const [useDescriptionEllipsis, setUseDescriptionEllipsis] = useState(true)
  const handleReadMore = () => {
    setUseDescriptionEllipsis(false)
  }

  const handleReflow = rleState => {
    const ele = document.getElementsByClassName(moreLinkClass)
    if (rleState.clamped) {
      ele[0].onclick = () => {
        handleReadMore()
      }
    }
  }

  const renderTruncatedDescription = useMemo(() => {
    return (
      <HTMLEllipsis
        basedOn="letters"
        ellipsisHTML={`... <span class="${moreLinkClass}">${_(
          'common.showMore',
        )}</span>`}
        maxLine={maxLine}
        unsafeHTML={description}
        onReflow={handleReflow}
      />
    )
  }, ['description'])

  const { secondaryColor } = useBaseTheme()

  return (
    <DescriptionText linkcolor={secondaryColor} {...rest}>
      {useDescriptionEllipsis ? (
        renderTruncatedDescription
      ) : (
        <DescriptionText dangerouslySetInnerHTML={{ __html: description }} />
      )}
    </DescriptionText>
  )
}

Description.defaultProps = {
  description: '',
  maxLine: 5,
  moreLinkClass: 'merchant-about-read-more',
}

Description.propTypes = {
  description: PropTypes.string,
  maxLine: PropTypes.number,
  moreLinkClass: PropTypes.string,
}

export default Description
