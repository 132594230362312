import React from 'react'
import PropTypes from 'prop-types'
import { get, map } from 'lodash'

import { Box, Flex } from 'Components/UI'
import { useBaseTheme } from 'Hooks'
import { _ } from 'Services/I18n'

import { clockGlyph } from 'Assets/Svg'

import {
  Duration,
  DurationIcon,
  Label,
} from 'Components/Blocks/ServiceCard/styles'

import { ContentTitle, Dash } from '../../styles'

import {
  CategoryContainer,
  CategoryName,
  OptionContainer,
  OptionsWrapper,
  PriceCross,
  PriceHolder,
  PriceLabel,
  PriceWrapper,
} from './styles'

const Options = ({ service, ...rest }) => {
  if (!service) {
    return null
  }

  if (!service.options.length && !service.pricing_categories.length) {
    return null
  }

  const { primaryColor } = useBaseTheme()

  const getPrice = item => {
    return (item.price || '').replace(/\.00$/, '')
  }

  const getRRP = item => {
    const rrp = get(item, 'recommended_retail_price')
    const price = get(item, 'price')

    return rrp === price ? null : (rrp || '').replace(/\.00$/, '')
  }

  const renderPricing = (item, showLabel = false, flexDirection = 'row') => {
    const price = getPrice(item)
    const recommendedRetailPrice = getRRP(item)
    const discountPercent = get(item, 'discount_percent')
    const priceLabel = get(service, 'price_label')

    return (
      <PriceWrapper color={primaryColor} flexDirection={flexDirection}>
        {discountPercent ? (
          <Label
            bgcolor="linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #E85815"
            color="#E85815"
            invert={0}
          >
            {Math.round(discountPercent)}% OFF
          </Label>
        ) : null}
        <Flex>
          <PriceHolder>
            {recommendedRetailPrice && (
              <PriceCross>{recommendedRetailPrice}</PriceCross>
            )}
            {price}
          </PriceHolder>
          {showLabel && priceLabel && (
            <Flex>
              <PriceLabel>{priceLabel}</PriceLabel>
            </Flex>
          )}
        </Flex>
      </PriceWrapper>
    )
  }

  const renderTitle = (title, duration) => {
    return (
      <>
        <CategoryName fontSize="16px">{title}</CategoryName>
        {duration ? (
          <Duration fontSize="14px" ml="8px">
            <DurationIcon color="#52525B" glyph={clockGlyph} />
            {duration}
          </Duration>
        ) : null}
      </>
    )
  }

  const renderPricingCategories = serviceOrOption => {
    const pricingCategories = get(serviceOrOption, 'pricing_categories')

    if (!pricingCategories?.length) {
      return null
    }

    return (
      <OptionContainer key={serviceOrOption.id}>
        <Flex mb="12px">
          {renderTitle(serviceOrOption.name, serviceOrOption.duration)}
        </Flex>
        {map(pricingCategories, category => {
          return (
            <CategoryContainer key={category.position}>
              <CategoryName>{category.category_name}</CategoryName>
              {renderPricing(category)}
            </CategoryContainer>
          )
        })}
      </OptionContainer>
    )
  }

  const renderOptions = options => {
    if (!options?.length) {
      return null
    }

    return (
      <OptionsWrapper>
        {map(options, option => {
          if (option.pricing_categories.length) {
            return renderPricingCategories(option)
          }

          return (
            <OptionContainer key={option.id}>
              <Flex>{renderTitle(option.name, option.duration)}</Flex>

              {!option.is_price_hidden && (
                <Flex>{renderPricing(option, true, 'row-reverse')}</Flex>
              )}

              {renderPricingCategories(option)}
            </OptionContainer>
          )
        })}
      </OptionsWrapper>
    )
  }

  return (
    <Box {...rest}>
      <ContentTitle>{_('common.options')}</ContentTitle>
      <Dash mb="32px" />
      {renderPricingCategories(service)}
      {renderOptions(service.options)}
    </Box>
  )
}

Options.defaultProps = {
  service: null,
}

Options.propTypes = {
  service: PropTypes.object,
}

export default Options
