import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'
import { Box, Button, Dash, Flex, Icon, Text } from 'Components/UI'
import { globeGlyph, messageSquare } from 'Assets/Svg'

export const Section = styled(Flex).attrs({})`
  flex-direction: column;
  gap: 12px;
`

export const Heading = styled(Text).attrs({
  fontSize: 14,
  mb: 3,
})`
  color: ${themeGet('colors.text')};
  text-align: left;
  font-weight: 700;
  cusrsor: default;
`

export const Separator = styled(Dash).attrs({
  my: '24px',
})``

export const Row = styled(Flex).attrs({})`
  gap: 8px;
  align-items: center;
  color: ${themeGet('colors.text')};

  input {
    margin: 0;
  }
`

export const MessageIcon = styled(Icon).attrs({
  glyph: messageSquare,
  size: 16,
})`
  fill: #52525b;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
`

export const Link = styled.a`
  font-size: 14px;
  color: #52525b;
  cursor: pointer;
  word-break: break-all;
  text-decoration: none;

  &:active,
  &:hover {
    opacity: 0.7;
    text-decoration: underline;
  }
`

export const WebsiteIcon = styled(Icon).attrs({
  glyph: globeGlyph,
  size: 16,
})`
  fill: #52525b;
`

export const WebsiteText = styled(Box).attrs({
  fontSize: '14px',
})`
  color: #52525b;
  cursor: pointer;
  word-break: break-all;

  &:active,
  &:hover {
    opacity: 0.7;
    text-decoration: underline;
  }
`

export const ModalContent = styled(Flex).attrs({
  width: ['100%', '500px'],
})`
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`

export const ModalText = styled(Text).attrs({
  fontSize: 2,
  mt: 3,
})`
  color: ${themeGet('colors.text')};
  line-height: 1.5;
  width: 100%;
`

export const ModalButton = styled(Button).attrs({
  fontSize: 2,
  mt: [32, 45],
})`
  color: ${({ color }) => color || themeGet('colors.font.secondary')};
  background-color: ${({ background }) =>
    background || themeGet('colors.goldenrod')};
  border-radius: ${themeGet('borderRadius.1')};
  font-weight: 700;
  text-transform: uppercase;

  align-items: center;
  display: flex;
  justify-content: center;
  height: 48px;
  width: 150px;
`
